body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.Mui-selected .MuiListItemIcon-root {
    color: #00a39d;
}
.Mui-selected .MuiTouchRipple-root:after {
    width: 5px;
    background-color: #00a39d;
    height: 100%;
    content: "|";
    float: right;
}
.makeStyles-addNewUserButton-30 {
    bottom: unset !important;
}
.makeStyles-addNewCategoryButton-68 {
    bottom: auto !important;
}
