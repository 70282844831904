.reportDetails__grid__item {
    min-width: 200px;
    padding: 15px;
}
.status {
    color: white;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 3px;
    padding: 0 8px;
    font-size: 1em;
    height: 1.85em;
    font-weight: 500;
}
.status .content {
    display: flex;
    min-width: max-content;
}
.status span {
    position: relative;
    top: 3px;
    margin-left: 5px;
}
.status.progress {
    background-color: #ffc200;
}
.status.received {
    background-color: #fe3c38;
}
.status.resolved {
    background-color: #00b557;
}
.image .status {
    float: right;
    margin-right: 15px;
    width: 60px;
    border-radius: 500px;
    height: 60px !important;
    text-align: center;
    box-shadow: 3px 9px 16px -10px rgba(0, 0, 0, 0.75);
}
.image .status span {
    display: none;
}
.image .status .MuiSvgIcon-root {
    font-size: 3rem;
    position: relative;
    right: 3px;
    top: 5px;
}
.reportDetails__image {
    width: 100%;
    max-height: 300px;
}

.reportDetails__popover__triggerButton {
    margin-right: 10px !important;
}

.reportDetails__popover__container {
    padding: 15px;
}

.reportDetails__popover__textarea {
    width: 100%;
}
.reportDetails__popover__button {
    margin: 15px !important;
}

.reportDetails_comments_icon {
    padding-right: 10px;
}

.reportDetails_comments_text {
    padding-bottom: 4px;
}
.detail-column-buttons button span{
    font-size: 13px;
}
@media screen and (max-width: 1900px) {
    .detail-column-buttons {
        display: grid;
        margin: auto;
    }
}
