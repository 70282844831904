@media all and (min-width: 480px) {
    .unauthenticated__form__container {
        width: 100%;
        padding: 60px 0;
    }
  
    .unauthenticated__form__container form {
        margin: 0 auto;
        max-width: 320px;
    }

    .unauthenticated__form__container p {
        font-size: 12px;
        text-align: center;
        color: gray;
    }

    .unauthenticated__form__container button {
        margin: 10px 0;
    }
}